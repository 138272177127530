import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'
import tracker from 'analytics/tracker'
import DatadogTracker from 'analytics/OmniTracker/DatadogTracker'
import { ObjectWithAnyProps } from 'interfaces/generalTypes'
import AnalyticsData from 'analytics/AnalyticsData'

window.addEventListener('DOMContentLoaded', () => {
  const analyticsData: AnalyticsData = (window as ObjectWithAnyProps).analytics

  if (analyticsData.init.datadog != null) {
    const commonInitData = {
      clientToken: analyticsData.init.datadog.clientToken,
      site: analyticsData.init.datadog.site,
      service: analyticsData.init.datadog.service,
      env: analyticsData.init.env,
      version: analyticsData.init.version,
      proxy: analyticsData.init.datadog.proxy,
    }

    datadogLogs.init({
      ...commonInitData,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: 'all',
      forwardReports: 'all',
      sessionSampleRate: 100,
      telemetrySampleRate: 0,
      usePartitionedCrossSiteSessionCookie: true,
    })

    datadogRum.init({
      ...commonInitData,
      applicationId: analyticsData.init.datadog.applicationId,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [
        { match: analyticsData.init.rootUrl, propagatorTypes: ['datadog'] },
      ],
    })

    datadogRum.startSessionReplayRecording()

    if (analyticsData.user != null) {
      const datadogUser = { ...analyticsData.user, id: analyticsData.user.id.toString() }
      datadogLogs.setUser(datadogUser)
      datadogRum.setUser(datadogUser)
    }

    // Register datadog tracker
    tracker.addTracker(new DatadogTracker(datadogRum, datadogLogs))
  } else {
    console.warn('Datadog analytics data not found, skipping initialization.')
  }
})
