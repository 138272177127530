import posthog from 'posthog-js'
import AnalyticsData from 'analytics/AnalyticsData'
import { ObjectWithAnyProps } from 'interfaces/generalTypes'
import tracker from 'analytics/tracker'
import PosthogTracker from 'analytics/OmniTracker/PosthogTracker'

window.addEventListener('DOMContentLoaded', () => {
  const analyticsData: AnalyticsData = (window as ObjectWithAnyProps).analytics

  if (analyticsData.init.posthog != null) {
    posthog.init(analyticsData.init.posthog.apiKey, {
      api_host: analyticsData.init.posthog.apiHost,
      // By default, PostHog stores all its data in cookies. This can cause the cookie header to become too
      // large (Heroku has a 8k limit). To avoid this, we can store the data in localStorage instead and only
      // store the user ID in a cookie.
      persistence: 'localStorage+cookie',
      session_recording: {
        maskAllInputs: false,
        maskInputOptions: {
          search: false,
          text: true,
          email: true,
          password: true,
        },
      },
      loaded: (instance) => {
        instance.register({
          visitId: analyticsData.init.visitId,
          environment: analyticsData.init.env,
          version: analyticsData.init.version,
        })

        if (analyticsData.user != null) {
          if (analyticsData.user.id.toString() !== instance.get_distinct_id()) {
            instance.identify(analyticsData.user.id.toString(), analyticsData.user)
          }
        }
      },
    })

    tracker.addTracker(new PosthogTracker(posthog))
  } else {
    console.warn('Posthog analytics data not found, skipping initialization.')
  }
})
